<template>
  <div class="item border_bottom">
    <div class="d_flex justify_content_between">
      <div class="d_flex">
        <div class="d_flex flex_column">
          <div class="d_flex justify_content_between">
            <div class="title">{{ title }}</div>
            <div class="sub_title">
              <div v-if="checkType !== null">
                <span v-if="checkType !== null">{{
                  checkType === 1 ? "+" : "-"
                }}</span>
                {{ tradeAmount }}
              </div>
              <div v-else>{{ formatQuota }}{{ unit }}</div>
            </div>
          </div>
          <div class="content d_flex justify_content_around">
            <span class="text_secondary" v-if="orderNo !== null">流水号：</span>{{ orderNo }}
          </div>
          <div class="content d_flex justify_content_around">
            <span class="text_secondary" style="width: 100%;">时间：{{ createdAt }}</span>
            <div class="content d_flex justify_content_end" v-clipboard:copy="orderNo"
              v-clipboard:success="handleCopySuccess" v-clipboard:error="handleCopyError" style="white-space:nowrap;"
              v-if="$route.name != 'pointsDetails'">
              [复制]
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Message } from "element-ui";
export default {
  props: {
    unit: {
      type: String,
      default: "",
    },
    createdAt: {
      type: String,
      default: "",
    },
    orderNo: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: "标题",
    },
    tradeAmount: {
      type: Number,
      default: 1,
    },
    checkType: {
      type: Number,
      default: 1,
    },
    imageUrl: {
      type: String,
      default: "",
    },
    borderBottom: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formatQuota() {
      return this.tradeAmount > 0 ? "+" + this.tradeAmount : this.tradeAmount;
    },
    formatUnit() {
      return this.tradeAmount >= 10 ? ".0元" : "元";
    },
  },
  methods: {
    handleCopySuccess() {
      Message({
        message: "复制流水号成功",
        iconClass: "x",
        center: true,
        customClass: "sucess_message",
      });
    },
    handleCopyError() {
      Message({
        message: "复制流水号失败",
        iconClass: "x",
        center: true,
        customClass: "sucess_message",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.copy_content {
  display: flex;
}

.d_flex {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.flex_column {
  flex-direction: column;
}

.justify_content_between {
  justify-content: space-between;
}

.justify_content_end {
  justify-content: end;
}

.item {
  width: 100%;
  box-sizing: border-box;
  padding: 0.75rem 0rem;
  box-sizing: border-box;
  line-height: 1.75;
}

.icon {
  display: block;
  width: 1rem;
  transform: translateY(0.1rem);

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.border_bottom {
  border-bottom: 0.75px solid #d4d4d481;
}

.title {
  font-weight: bold;
}

.sub_title {
  padding-left: 0.25rem;
  color: red;
  font-size: 0.8rem;
  font-weight: bold;
}

.content {
  font-size: 0.85rem;
  text-align: left;
}

.text_secondary {
  color: var(--gray);
}
</style>

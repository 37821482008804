<template>
  <Container theme="light" ref="list" @scroll.native="loadMore">
    <template v-if="checkResponseStatus">
      <MyPaymentItem
        v-for="(item, index) in data"
        :key="index"
        :title="item.tradeTypeDesc"
        :orderNo="item.orderNo"
        :createdAt="item.createdAt"
        :checkType="item.checkType"
        :tradeAmount="item.tradeAmount"
        :class="{ mb_6rem: data.length > 5 && index === data.length - 1 }"
      ></MyPaymentItem>
    </template>
    <NoData v-else-if="!checkResponseStatus && !isLoading"></NoData>
  </Container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import NoData from "@/components/NoData";
import Container from "../components/Container.vue";
import MyPaymentItem from "../components/MyPaymentItem.vue";
export default {
  components: {
    NoData,
    Container,
    MyPaymentItem,
  },
  data() {
    return {
      page: 1,
      limit: 20,
      data: [],
      dataResponse: true,
    };
  },
  watch: {
    amountLogs: {
      handler: function(newVal) {
        this.changeSpinnerStatus(true);
        if (newVal) {
          if (newVal === []) return;
          this.data.push(...newVal);
          setTimeout(() => {
            this.changeSpinnerStatus(false);
          }, 800);
        } else {
          this.changeSpinnerStatus(false);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapState("spinner", ["isLoading"]),
    ...mapState("amount", ["amountLogs"]),
    checkResponseStatus() {
      return this.data && this.data.length > 0 ? true : false;
    },
  },
  methods: {
    ...mapActions("amount", ["getAmountLogs"]),
    ...mapActions("spinner", ["changeSpinnerStatus"]),
    loadMore(e) {
      if (!this.dataResponse) return;
      let { scrollTop, clientHeight, scrollHeight } = e.target;
      if (scrollHeight === scrollTop + clientHeight) {
        this.page++;
        this.getAmountLogs({
          page: this.page,
          limit: this.limit,
        }).then((res) => {
          if (res.data && res.data.length === 0) {
            this.dataResponse = false;
          }
        });
      }
    },
  },
  created() {
    this.data = [];
    this.getAmountLogs({ page: this.page, limit: this.limit });
  },
};
</script>
